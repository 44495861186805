import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IProject } from '../../../@types/@acs/project';
import { IFile, IFileState, IFolderContent, IHistory } from '../../../@types/@acs/file';
import axios from '../../../utils/axios';
import { RootState } from 'redux/store';

// ----------------------------------------------------------------------

const initialState: IFileState = {
    isLoading: false,
    error: null,
    files: [],
    folderContents: [],
    histories: [],
};

const slice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

         // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.files = initialState.files;
            state.histories = initialState.histories;
        },

        // GET FILES
        getFilesSuccess(state, action) {
            state.isLoading = false;
            state.files = action.payload;
        },

        // GET SELECTED FOLDER CONTENTS
        setSelectedFolderContents(state, action) {
            state.folderContents = action.payload
            state.isLoading = false;
        },

        // ADD FILE SUCCESS
        addFileSuccess(state) {
            state.isLoading = false;
        },

        // UPDATE FILE SUCCESS
        updateFileSuccess(state) {
            state.isLoading = false;
        },

        // DELETE FILE SUCCESS
        deleteFileSuccess(state) {
            state.isLoading = false;
        }, 

        // DOWNLOAD FILE SUCCESS
        downloadFileSuccess(state) {
            state.isLoading = false;
        }, 

        // GET HISTORIES
        getWebhookHistorySuccess(state, action) {
            state.isLoading = false;
            state.histories = action.payload;
        },

        // REGIST TOPFOLDER WEBHOOK SUCCESS
        registWebhookSuccess(state) {
            state.isLoading = false;
        },

        // DELETE TOPFOLDER WEBHOOK SUCCESS
        deleteWebhookSuccess(state) {
            state.isLoading = false;
        },

        // SET START TOP FOLDER WEBHOOK SUCCESS
        setStartTopFolderWebhookSuccess(state) {
            state.isLoading = false;
        },
    }
});

export default slice.reducer;

// Actions
export const {
    initialize,
    setSelectedFolderContents,
    // setAxiosHeader
} = slice.actions;

async function fetchFoldersRecursive(projectId: string, itemId: string) {
    const response = await axios.get(`/aps/o/data/v1/projects/${projectId}/folders/${itemId}/contents?page[limit]=100`);

    // API로부터 반환된 데이터를 가져옵니다.
    const data = response.data.data;

    // 하위 폴더와 항목을 저장할 배열
    const allData = [];

    // API 응답 데이터를 처리합니다.
    for (const item of data) {
        if (item.type === 'folders') {
            // "folders" 타입인 경우 처리할 작업을 수행합니다.
            // console.log(item.attributes.name);

            // 재귀 호출: 하위 폴더를 가져오기 위해 동일한 함수를 다시 호출합니다.
            item.children = await fetchFoldersRecursive(projectId, item.id);
            allData.push(item); // 현재 폴더를 배열에 추가합니다.
        } else {
            // "folders" 타입이 아닌 경우 처리할 작업을 수행합니다.
            // console.log(item.attributes.displayName);
            allData.push(item); // 항목을 배열에 추가합니다.
        }
    }

    return data;
}

//==============================================================================
export function getFilesAll(project: IProject) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const files = await axios.get(`aps/o/project/v1/hubs/${project.hubId}/projects/${project.id}/topfolders?force=true`);

            const topFolders = files.data;   //files.data[0];

            const allDirectory = await Promise.all(topFolders.map(async (item: any) => {
                // 최상위 폴더에 파일인 경우엔 재귀 함수 호출 안함
                if (item.type === 'folders') {
                    // 재귀 함수 호출
                    try {
                        item.children = await fetchFoldersRecursive(project.id, item.id);
                    } catch (error) {
                        console.error('오류 발생:', error);
                    }
                }

                return {
                    ...item,
                };
            }));

            // const folders = await axios.get(`/aps/o/data/v1/projects/${project.id}/folders/${files.data[0].id}/contents?page[limit]=100`);
            // const subFolders = await Promise.all(folders.data.data.map(async (item: any) => {
            //     // 최상위 폴더에 파일인 경우엔 재귀 함수 호출 안함
            //     if (item.type === 'folders') {
            //         // 재귀 함수 호출
            //         try {
            //             item.children = await fetchFoldersRecursive(project.id, item.id);
            //         } catch (error) {
            //             console.error('오류 발생:', error);
            //         }
            //     }

            //     return {
            //         ...item,
            //     };
            // }));

            const list: IFile[] = allDirectory.map((data: any) => {
                return {
                    children: data.children,
                    type: data.type,
                    id: data.id,
                    attributes: data.attributes,
                    links: data.links,
                    relationships: data.relationships,
                } as IFile;
            });

            dispatch(slice.actions.getFilesSuccess(list));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

//------------------------------------------------------------------------------
export function getFiles(project: IProject) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const files = await axios.get(`aps/o/project/v1/hubs/${project.hubId}/projects/${project.id}/topfolders?force=true`);

            const topFolders = files.data;   //files.data[0];

            const list: IFile[] = topFolders.map((data: any) => {
                return {
                    children: data.children,
                    type: data.type,
                    id: data.id,
                    attributes: data.attributes,
                    links: data.links,
                    relationships: data.relationships,
                } as IFile;
            });

            dispatch(slice.actions.getFilesSuccess(list));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};

// 상태 갱신 로직의 리듀서에서 사용할 updateFolder 함수에 반환 타입 명시
const updateFolder = (folders: any[], folderId: string, contents: any[]): any[] => {
    return folders.map(folder => {
        if (folder.id === folderId) {
            // 선택한 폴더 ID와 일치하는 폴더에 children을 업데이트
            return { ...folder, children: contents };
        } else if (folder.children) {
            // 일치하지 않으면, 재귀적으로 children을 탐색
            return { ...folder, children: updateFolder(folder.children, folderId, contents) };
        }
        return folder;
    });
};

export function fetchFolderContents(projectId: string, folderId: string) {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/aps/o/data/v1/projects/${projectId}/folders/${folderId}/contents?page[limit]=100`);
            const folderContents = response.data.data;

            const currentFiles = getState().file.files;

            // updateFolder 함수를 사용하여 업데이트된 파일 목록을 생성합니다.
            const updatedFiles = updateFolder(currentFiles, folderId, folderContents);

            const list: IFile[] = updatedFiles.map((data: any) => {
                return {
                    children: data.children,
                    type: data.type,
                    id: data.id,
                    attributes: data.attributes,
                    links: data.links,
                    relationships: data.relationships,
                } as IFile;
            });

            // 업데이트된 파일 목록으로 상태를 갱신합니다.
            dispatch(slice.actions.getFilesSuccess(list));

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFolderContents(projectId: string, folderId: string) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/aps/o/data/v1/projects/${projectId}/folders/${folderId}/contents?page[limit]=100`);
            const folderContents = response.data.data;
            const folderVersions = response.data.included;

            const list: IFolderContent[] = folderContents.map((content: any) => {
                // 파일(item)인 경우에만 버전(version)을 매핑하여 반환. 폴더의 경우 version은 undefined
                const version = folderVersions
                    ? folderVersions.find((version: any) => content.type === 'items' && version.relationships.item.data.id === content.id)
                    : undefined;
                return {
                  attributes: content.attributes,
                  id: content.id,
                  links: content.links,
                  relationships: content.relationships,
                  type: content.type,
                  version: version ? version : undefined
                } as IFolderContent;
              });

            dispatch(slice.actions.setSelectedFolderContents(list));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function addFile(projectId: string, folderId: string, file: any) {
    return async (dispatch: Dispatch) => {
        try {
            // dispatch(slice.actions.startLoading());
            
            const response = await axios.post(`/aps/w/files/projects/${projectId}/folders/${folderId}/files/${file.name}`, file.binaryData, {
                headers: {
                  'Content-Type': 'application/octet-stream', // 형식을 binary로 설정
                },
            });
            
            dispatch(slice.actions.addFileSuccess());

            return response.statusText;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function updateFile(projectId: string, fileId: string, name: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const response = await axios.post(`/aps/w/files/projects/${projectId}/rename`, { newDisplayName: name, tipId: fileId });

            dispatch(slice.actions.updateFileSuccess());

            return response.statusText;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function deleteFile(projectId: string, fileId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const response = await axios.delete(`/aps/w/files/projects/${projectId}/fileid/${fileId}`);
            
            dispatch(slice.actions.deleteFileSuccess());

            return response.statusText;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function downloadFile(objectKey: string, fileName: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            // const type = axios.defaults.headers.common['acs-project-type'];
            const bucketKey = 'wip.dm.prod'; // 고정
            const response = await axios.get(`/aps/o/oss/v2/buckets/${bucketKey}/objects/${objectKey}/signeds3download?fileName=${fileName}`);
            window.open(response.data.url, '_self');

            dispatch(slice.actions.downloadFileSuccess());
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
};

// 되기도하고, 안되기도 하고...?
export function getVersions(projectId: string, versionId: string) {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.post(`/aps/o/data/v1/projects/${projectId}/versions/refs`, { versionId: versionId });
            const refs = response.data.data;
            console.log(refs);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function setStartTopFolderWebhook(folderId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const system = 'data';
            const addEvent = 'dm.version.added';
            const editEvent = 'dm.version.modified';
            const deleteEvent = 'dm.version.deleted';

            const projectId = axios.defaults.headers.common['acs-project-id'] as string;
            const hubId = axios.defaults.headers.common['acs-hub-id'] as string
            const body = {
                projectId: projectId,
                hubId: hubId,
                scope: {
                    folder: folderId
                }
            };

            await axios.post(`/aps/o/webhooks/v1/systems/${system}/events/${addEvent}/hooks`, body);
            await axios.post(`/aps/o/webhooks/v1/systems/${system}/events/${editEvent}/hooks`, body);
            await axios.post(`/aps/o/webhooks/v1/systems/${system}/events/${deleteEvent}/hooks`, body);

            dispatch(slice.actions.setStartTopFolderWebhookSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getWebhookHistory() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const histories = await axios.get(`/aps/w/webhooks/histories`);

            const list: IHistory[] = histories.data.map((data: any) => {
                return {
                    createdAt: data.createdAt,
                    fileName: data.fileName,
                    folderName: data.folderName,
                    hookAt: data.hookAt,
                    id: data.id,
                    operation: data.operation,
                    userEmail: data.userEmail,
                    userName: data.userName,
                } as IHistory;
            });

            dispatch(slice.actions.getWebhookHistorySuccess(list));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function registWebhook(project: IProject) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const topFolders = await axios.get(`aps/o/project/v1/hubs/${project.hubId}/projects/${project.id}/topfolders?force=true`);
            const topFolderId = topFolders.data[0].id;
         
            const data = {
                "scope": {
                    "folder": topFolderId
                },
                "hubId": project.hubId,
                "projectId": project.id
            };

            const response = await axios.post('/aps/o/webhooks/v1/systems/events/hooks', data);

            const hasConflictError = response.data.some((res: { value: { status: number; code: string; }; }) => 
                res.value && res.value.status !== 200 && res.value.code === "CONFLICT_ERROR"
            );

            if (hasConflictError) {
                throw new Error('Conflict error occurred while deleting webhooks.');
            } else {
                return response;
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        } finally {
            dispatch(slice.actions.registWebhookSuccess());
        }
    };
};

export function deleteWebhook(project: IProject) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const topFolders = await axios.get(`aps/o/project/v1/hubs/${project.hubId}/projects/${project.id}/topfolders?force=true`);
            const topFolderId = topFolders.data[0].id;

            const response = await axios.get(`/aps/o/webhooks/v1/hook`);

            const matchedHooks = response.data.data.filter((hook: { scope: { folder: any; }; }) => hook.scope.folder === topFolderId);
            if (matchedHooks.length > 0) {
                const data = matchedHooks.map((hook: { hookId: any; event: any; scope: { folder: any; }; }) => ({
                    "hookId": hook.hookId,
                    "event": hook.event,
                    "scope_folder": hook.scope.folder
                }));
                const config = {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                const result = await axios.delete(`/aps/o/webhooks/v1/systems/events/hooks`, config);
                return result;
            } else {
                throw new Error('No matched hooks found, skipping DELETE request.');
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            throw error;
        } finally {
            dispatch(slice.actions.deleteWebhookSuccess());
        }
    };
};

export function getIsAccountAdmin(projectId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const response = await axios.get(`/aps/w/auth/me`);
            const user = response.data.sub;

            const response2 = await axios.get(`/aps/o/construction/admin/v1/projects/${projectId}/users?force=true`);
            const users = response2.data.results;

            const matchedUser = users.find((u: any) => u.autodeskId === user);
            if (matchedUser) {
                return matchedUser.accessLevels.accountAdmin;
            } else {
                // throw new Error('User not found in project users');
                console.error('User not found in project users');
                return false;
            }
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    };
};