import { ReactNode, useEffect } from "react";
import { useSelector } from "../../redux/store";
import { useAuthContext } from "../../auth/useAuthContext";

interface Props {
  children: ReactNode;
}

export default function ErrorBoundary({ children }: Props) {
  const { logout } = useAuthContext();

  const { error: projectError } = useSelector((state: any) => state.project);
  const { error: issueError } = useSelector((state: any) => state.issue);
  const { error: requestError } = useSelector((state: any) => state.request);
  const { error: jobError } = useSelector((state: any) => state.job);
  const { error: ossError } = useSelector((state: any) => state.oss);
  const { error: notificationError } = useSelector((state: any) => state.notification);

  useEffect(() => {
    if (projectError?.status === 401
      || issueError?.status === 401
      || requestError?.status === 401
      || jobError?.status === 401
      || ossError?.status === 401
      || notificationError?.status === 401) {
      logout();
    }    
    
  }, [projectError, issueError, requestError, jobError, ossError, notificationError]);

  return <> {children} </>;
}