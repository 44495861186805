import { createContext, useEffect, useReducer, useCallback, useMemo, useState } from 'react';
import {
    AppState,
    useAuth0,
    Auth0Provider,
    LogoutOptions,
    PopupLoginOptions,
  } from '@auth0/auth0-react';
// config
import config from '../configs/config';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setFirstLoginSession, setSession, setUserSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, Auth0ContextType } from './types';

import { useDispatch } from '../redux/store';
import { initialize as initializeProject } from '../redux/slices/@acs/project';
import { initialize as initializeIssue } from '../redux/slices/@acs/issue';
import { initialize as initializeRequest } from '../redux/slices/@acs/dcrequest';
import { initialize as initializeJob } from '../redux/slices/@acs/dcjob';
import { initialize as initializeOSS } from '../redux/slices/@acs/oss';
import { initialize as initializeNotification } from '../redux/slices/@acs/notification';
import { initialize as initializeFile } from '../redux/slices/@acs/file';
import { initialize as initializeForm } from '../redux/slices/@acs/form';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<Auth0ContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
    children: React.ReactNode;
};
  
function AuthProviderWrapper({
  children
}: AuthProviderProps) {

  const { isAuthenticated, user, isLoading, loginWithRedirect, logout } = useAuth0();

  const [state, dispatch] = useReducer(reducer, initialState);

  const appDispatch = useDispatch();

  const storageAvailable = localStorageAvailable();
  
  const initialize = useCallback(async () => {
    try {
      const tmp = storageAvailable ? localStorage.getItem('auth') : '';
      let auth: AuthStateType = initialState;

      if (tmp) {
        auth = JSON.parse(tmp);
      }

      try {
        if (auth.isAuthenticated) {
          auth.user = user!;
          auth.isAuthenticated = true;
        } else {
          auth.user = null;
          auth.isAuthenticated = false;
        }
      } catch (error) {
        console.log(error);
        auth.user = null;
        auth.isAuthenticated = false;
      } finally {
        setUserSession({ ...auth, isInitialized: true });
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: auth.isAuthenticated,
            user: auth.user,
          },
        });
      }
    } catch (error) {
      console.log(error);
      setUserSession(initialState);
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // const handleLogout = useCallback(async () => {
  const handleLogout = useCallback(
    async (options?: LogoutOptions) => {
      try {
        localStorage.setItem('logout', 'logout');
        await logout({    // logout?.(options);
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
        // setUserSession(null);
        // dispatch({
        //   type: Types.LOGOUT,
        // });
        // appDispatch(initializeProject());
        // appDispatch(initializeIssue());
        // appDispatch(initializeRequest());
        // appDispatch(initializeJob());
        // appDispatch(initializeOSS());
        // appDispatch(initializeNotification());
        // appDispatch(initializeFile());
        // appDispatch(initializeForm());
      }
    }, [logout]
  );

  const logoutComplete = useCallback(async () => {
    try {
      localStorage.removeItem('logout');
      setUserSession(null);
      dispatch({
        type: Types.LOGOUT,
      });
      appDispatch(initializeProject());
      appDispatch(initializeIssue());
      appDispatch(initializeRequest());
      appDispatch(initializeJob());
      appDispatch(initializeOSS());
      appDispatch(initializeNotification());
      appDispatch(initializeFile());
      appDispatch(initializeForm());
    } catch (error) {
      console.log(error);
    }
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = isAuthenticated ? 'authenticated' : 'unauthenticated';

  const status = isLoading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: {
        ...user,
        // displayName: user?.name,
        // photoURL: user?.picture,
        // role: user?.role,
      },
      method: 'auth0',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      loginWithRedirect,
      logout: handleLogout,
      logoutComplete,
    }),
    [state.isAuthenticated, state.isInitialized, user, status, loginWithRedirect, handleLogout, logoutComplete, ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
  
// ----------------------------------------------------------------------

// export const AuthProvider = ({ children }: AuthProviderProps) => {
export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { user } = useAuth0();
  
  const domain = config.auth0.domain ?? '';
  const clientId = config.auth0.clientId ?? '';
  const redirectUri = config.auth0.callbackUrl ?? '';
  const audience = config.auth0.audience ?? '';

  async function login() {
    state.isInitialized = true;
    state.isAuthenticated = true;
    state.user = user!;

    setUserSession(state);
    dispatch({
      type: Types.LOGIN,
      payload: {
        user: state.user,
      },
    });
  }

  const onRedirectCallback = useCallback(async (appState?: AppState) => {
    await login();
    setFirstLoginSession(state.isInitialized.toString());
    window.location.replace(appState?.returnTo || window.location.pathname);
  }, []);

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      // cacheLocation="localstorage"
    >
      <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </Auth0Provider>
  );
};