import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { IDataConnectorJob, IDataConnectorJobState } from '../../../@types/@acs/dataConnector';
import { IOSSObject, IOSSState } from '../../../@types/@acs/oss';


// ----------------------------------------------------------------------

const initialState: IOSSState = {
    isLoading: false,
    isUploading: false,
    error: null,
    issueReportTemplates: [],
};

const slice = createSlice({
    name: 'oss',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        startUploading(state) {
            state.isUploading = true;
        },

        endUploading(state) {
            state.isUploading = false;
        },

         // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.isUploading = false;
            state.error = action.payload;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.isUploading = initialState.isUploading
            state.error = initialState.error;
            state.issueReportTemplates = initialState.issueReportTemplates;
        },

        // GET ISSUE REPORT TEMPLATES
        getIssueReportTemplatesSuccess(state, action) {
            state.isLoading = false;
            state.issueReportTemplates = action.payload;
        },
    }
});

export default slice.reducer;

// Actions
export const {
    initialize
} = slice.actions;

export function getIssueReportTemplates(hubId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const bucketKey = `hub.${hubId}.issue-report-template`;

            const response = await axios.get(`/aps/oss/buckets/${bucketKey}/objects?force=true`);

            const list: IOSSObject[] = response.data.map((data: any) => {
                return {
                    bucketKey: data.bucketKey,
                    objectId: data.objectId,
                    objectKey: data.objectKey,
                    size: data.size,
                    updatedAt: data.updatedAt? new Date(data.updatedAt) : null,
                    updatedByName: data.updatedBy?.name,
                    updatedByEmail: data.updatedBy?.email
                } as IOSSObject;
            });

            dispatch(slice.actions.getIssueReportTemplatesSuccess(list));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// export function uploadIssueTemplates(bucketKey: string, files: File[]) {
//     return async (dispatch: Dispatch) => {
//         try {
//             dispatch(slice.actions.startUploading());

//             for (const file of files) {
//                 let formData = new FormData();
//                 formData.append('file', file);
//                 await axios.post(`/aps/oss/buckets/${bucketKey}/objects/${file.name}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
//             }
            
//             dispatch(slice.actions.endUploading());
//         } catch (error) {
//             dispatch(slice.actions.hasError(error));
//         }
//     };
// }