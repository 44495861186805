import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IProject, IProjectState } from '../../../@types/@acs/project';
import axios from '../../../utils/axios';


// ----------------------------------------------------------------------

const initialState: IProjectState = {
    isLoading: false,
    error: null,
    projects: [],
    project: null
};

const slice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

         // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.project = initialState.project;
            state.projects = initialState.projects;
        },

        // GET PROJECTS
        getProjectsSuccess(state, action) {
            state.isLoading = false;
            state.projects = action.payload;
        },

        // SET PROJECT STATUS
        setProjectStatus(state, action) {
            const { id, isFavorite, isDefault } = action.payload;

            state.projects = state.projects.map((project) => {
                if (project.id === id) {
                    return {
                        ...project,
                        isFavorite,
                        isDefault
                    }
                }

                if (isDefault) {
                    return {
                        ...project,
                        isDefault: false
                    }
                }

                return project;
            });
        },

        // SET MANAGE PROJECT
        setProjectManage(state, action) {
            const project = state.projects.find(m => m.id === action.payload);
            state.project = (project) ? project : null;
        },

        // SET AXIOS HEADER (PROJECT INFO)
        setAxiosHeader(state, action) {
            axios.defaults.headers.common['acs-hub-id'] = `${action.payload.hubId}`;
            axios.defaults.headers.common['acs-container-id'] = `${action.payload.containerId}`;
            axios.defaults.headers.common['acs-project-id'] = `${action.payload.id}`;
            axios.defaults.headers.common['acs-project-type'] = `${action.payload.type}`;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    initialize,
    setProjectManage,
    setAxiosHeader
} = slice.actions;

export function getProjects() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            /// 자체 DB에 프로젝트 ID 등록 여부 확인 (기본 프로젝트[isDefault] / 프로젝트 즐겨찾기[isFavorite] / 프로젝트 ID[projectId])
            const projects = await axios.get('/acs/projects?force=true');
            const existingProjectIds = projects.data.map((item: { projectId: string; }) => item.projectId.startsWith('b.') ? item.projectId.substring(2) : item.projectId);
            /// 토큰
            // console.log(projects.config.headers.Authorization);
            
            /// APS 프로젝트 조회
            const apsProjects = await axios.get('/aps/w/projects?force=true');
            const updatedProjects = await Promise.all(apsProjects.data.map(async (item: { id: string; }) => {
                const projectId = item.id.startsWith('b.') ? item.id.substring(2) : item.id;
                
                if (!existingProjectIds.includes(projectId)) {
                    const initData = {
                        isDefault: false,
                        isFavorite: false
                    };
                    // axios.defaults.headers.common['Content-Type'] = 'application/json';
                    await axios.put(`/acs/projects/${projectId}`, initData);
                    return {
                        ...item,
                        isDefault: initData.isDefault,
                        isFavorite: initData.isFavorite,
                    };
                } else {
                    const validProject = projects.data.find((project : any) => project.projectId === projectId);
                    return {
                        ...item,
                        isDefault: validProject.isDefault,
                        isFavorite: validProject.isFavorite,
                    };
                }
            }));

            const list: IProject[] = updatedProjects.map((data: any) => {
                return {
                    id: data.id,
                    containerId: data.containerId,
                    name: data.name,
                    type: data.type,
                    hubId: data.hubId,
                    hubName: data.hubName,
                    isDefault: data.isDefault,
                    isFavorite: data.isFavorite,
                } as IProject;
            });

            dispatch(slice.actions.getProjectsSuccess(list));

            /// 기본 프로젝트 여부 확인 시 활용
            return list;
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// 프로젝트 즐겨찾기 설정
export function setProjectFavorite(id: string, isFavorite: boolean) {
    return async (dispatch: Dispatch) => {
        try {
            const projectId = id.startsWith('b.') ? id.substring(2) : id;
            const projects = await axios.get('/acs/projects?force=true');
            const project = projects.data.find((project : any) => project.projectId === projectId);
            await axios.put(`/acs/projects/${projectId}`, { isDefault: project.isDefault, isFavorite: isFavorite });
            // dispatch는 b. 포함된 프로젝트명으로 처리
            dispatch(slice.actions.setProjectStatus({ id, isFavorite }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
};

// 프로젝트 기본 설정
export function setProjectDefault(id: string) {
    return async (dispatch: Dispatch) => {
        try {
            const projectId = id.startsWith('b.') ? id.substring(2) : id;
            const projects = await axios.get('/acs/projects?force=true');
            const project = projects.data.find((project : any) => project.projectId === projectId);
            await axios.put(`/acs/projects/${projectId}`, { isDefault: true, isFavorite: project.isFavorite });
            dispatch(slice.actions.setProjectStatus({ id, isDefault: true }));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}