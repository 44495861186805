import { JSXElementConstructor, Key, ReactElement, ReactFragment, ReactPortal, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from '../../../redux/store';

import { firebaseAppInstance } from '../../../firebase';
import { getMessaging, getToken } from 'firebase/messaging';
import config from '../../../configs/config';

// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Stack, AppBar, Toolbar, IconButton, Typography, Tooltip, Box, Container, Card, Grid, useMediaQuery, LinearProgress, Button } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../../config-global';
// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
import { useSnackbar } from '../../../components/snackbar';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import { getCurrentWeather, getFiveDayWeatherForecast, setInitializeForecast } from 'redux/slices/@acs/forecast';
import { ForecastData } from '../../../@types/@acs/forecast';
import { isFcmTokenRegistrationNeeded, registDevice } from 'redux/slices/@acs/fcmAlarm';
import ConfirmDialog from 'components/confirm-dialog';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

type Props = {
  onOpenNav?: VoidFunction;
};

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column', // 컨텐츠 방향을 세로로 설정
  flexGrow: 0.7, // 부모 컨테이너의 남은 공간을 모두 차지하도록 설정(1=100%)
  padding: theme.spacing(1, 1), // padding: theme.spacing(2, 2.5),
  // borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // minWidth: 300, // 최소 너비를 설정합니다. 필요에 따라 조정하세요.
  overflow: 'auto', // 내용이 넘칠 경우 스크롤을 표시합니다.

  // backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const dispatch = useDispatch();

  const { isManualAdd } = useSelector((state: any) => state.fcmAlarm);

  const { isLoading : isForecastLoading, weather, forecast } = useSelector((state: any) => state.forecast);

  const { enqueueSnackbar } = useSnackbar();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const { themeLayout, themeStretch } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const [openFcmAlarmConfirm, setOpenFcmAlarmConfirm] = useState(false);

  const isManualAddRef = useRef(isManualAdd);

  const handleFcmAlarm = async () => {
    try {
      setOpenFcmAlarmConfirm(false);

      const messaging = getMessaging(firebaseAppInstance);
      const fcmToken = await getToken(messaging, {
        // 프로젝트설정 > 클라우드메시징 > 웹 구성의 웹푸시인증서
        vapidKey: config.fcm.vapidKey,
      });
      if (fcmToken) {
        await dispatch(registDevice(fcmToken));
        enqueueSnackbar('디바이스 등록 완료. 현재 접속한 디바이스에서 알림을 받습니다.', { variant: 'success' });
      } else {
        enqueueSnackbar('Alarm Token is Null', { variant: 'error' });
      }
      // await dispatch(loadingSuccess(false));
    } catch (error) {
      console.log(error);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ko-KR', {
      month: '2-digit',
      day: '2-digit',
      weekday: 'short',
    }).replace('. ', '/').replace('. ', '').replace('.', ')'); // '07/01(월)' 형식으로 변환
  };

  async function monitorGeolocationPermission() {
    try {
      const permission = await navigator.permissions.query({ name: 'geolocation' });
      // 권한 상태가 변경될 때 실행되는 이벤트 핸들러
      permission.onchange = async () => {
        if (permission.state === 'granted') {
          await requestGeolocation();
          await dispatch(getFiveDayWeatherForecast());
        } else { // prompt, denied
          dispatch(setInitializeForecast());
          enqueueSnackbar(`날씨 위젯을 활성화하기 위해서는 '사이트 권한 설정'의 '위치 권한 허용'이 필요합니다.`, { variant: 'warning', anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 5000  });
        }
      };
    } catch (error) {
      console.error(error);
    }
  }

  async function requestGeolocation(): Promise<GeolocationPosition | null> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position);
          },
          (error) => {
            console.error(error);
            // reject(null);
            resolve(null); // 거부 or 기본값된 경우에도 에러 대신 null을 반환하여 처리
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
        reject(null);
      }
    });
  }

  async function monitorNotificationPermission() {
    try {
      const permission = await navigator.permissions.query({ name: 'notifications' });
      // 권한 상태가 변경될 때 실행되는 이벤트 핸들러
      permission.onchange = async () => {
        if (permission.state === 'granted') {
          if (isManualAddRef.current === false) {
            await requestNotificationPermission();
          }
        } else {  // prompt, denied
          enqueueSnackbar(`알림을 받기 위해서는 '사이트 권한 설정'의 '알림 권한 허용'이 필요합니다.`, { variant: 'warning', anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 5000  });
        }
      };
    } catch (error) {
      console.error(error);
    }
  }

  function requestNotificationPermission(): Promise<NotificationPermission | null> {
    return new Promise((resolve, reject) => {
      if (!("Notification" in window)) {
        reject(new Error("이 브라우저는 알림을 지원하지 않습니다."));
        return;
      }
      try {
        Notification.requestPermission()
          .then(async (permission) => {
            if (permission === 'granted') {
               // > > > isFcmTokenRegistrationNeeded + handleFcmAlarm = handleAddDevice
              const isFcmAlarmConfirm = await dispatch(isFcmTokenRegistrationNeeded());
              if (isFcmAlarmConfirm) {
                // 알림 창 띄우기
                setOpenFcmAlarmConfirm(true);
              }
              resolve(permission);
            } else {
              resolve(null); // 거부 or 기본값으로 변경된 경우에도 에러 대신 null을 반환하여 처리
            }
          })
          .catch((error) => {
            reject(new Error('알림 권한 요청 중 오류가 발생했습니다.'));
            return;
          });
      } catch (error) {
        console.log(error);
      }
    });
  }

  useEffect(() => {
    // isManualAddRef.current가 항상 최신 상태 값을 가리키게 되어, permission.onchange 이벤트 핸들러 내에서도 최신 상태를 참조할 수 있음
    isManualAddRef.current = isManualAdd;
  }, [isManualAdd]);

  useEffect(() => {
    // > > > 권한 상태 변경 감지
    monitorGeolocationPermission();

    monitorNotificationPermission();

    // const fetchData = async () => {
    //   // await dispatch(getCurrentWeather());
    //   await dispatch(getFiveDayWeatherForecast());
    // };
    // fetchData();

    const compareDate = async () => {
      // 오늘 날짜
      const today = new Date();
      const todayString = today.toISOString().slice(0, 10);

      // 5일 중 첫번째 날짜
      const forecastDate = forecast[0].date;
      // const date = new Date(forecastDate);
      // const day = date.toLocaleDateString('ko-KR', { weekday: 'long' });

      if (todayString === forecastDate) {
        // console.log('오늘 날씨 정보가 최신화 되었습니다.');
      } else {
        // console.log('오늘 날씨 정보가 최신화 되지 않았습니다.');
        await dispatch(getFiveDayWeatherForecast());
      }
    };
    
    // 새로고침 했을 때, 최초 로그인하며 가져온 첫번째 날짜와 오늘 날짜를 비교하여 다르면 다시 가져오기
    if (forecast) {
      compareDate();
    }
  }, []);

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}


      <Container maxWidth={themeStretch ? false : 'xl'}>

        {!isSmallScreen ? (
          <>
            <Grid container spacing={2}>
              {isForecastLoading ? (
                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card sx={{ p: 3 }}>
                      <LinearProgress color="inherit" sx={{ width: '100%', height: '1px', borderRadius: '5px' }} />
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                forecast && forecast.map((forecast: ForecastData, index: Key | null | undefined) => (
                  <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 1, py: 0.5, borderRadius: '16px', boxShadow: 3, mt: 1 }}>
                      {/* 상단 구역 */}
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', mb: 0.5 }}>
                        <Typography variant="body2" sx={{ color: 'text.primary', textAlign: 'left' }}>
                          {forecast.area}
                        </Typography>
                        <Typography variant="overline" sx={{ color: 'text.secondary', textAlign: 'right' }}>
                          {formatDate(forecast.date)}
                        </Typography>
                      </Box>
                      {/* 중단 구역 */}
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mb: 0.5 }}>
                        <Box component="img" src={forecast.weatherIconUrl} alt="Weather icon" sx={{ width: '30px', height: '30px', mr: 0.5 }} />
                        <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                          {forecast.description}
                        </Typography>
                      </Box>
                      {/* 하단 구역 */}
                      <Box sx={{ textAlign: 'center', width: '100%' }}>
                        <Typography variant="caption" sx={{ color: 'text.secondary', display: 'block' }}>
                          ↑{Math.round(forecast.maxTemp)}°C　　↓{Math.round(forecast.minTemp)}°C
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          </>
        ) : (
          <>
            {/* 위젯은 화면이 넓을 때만 렌더링 되도록 주석 처리 */}
            {/* 5개의 Card : maxWidth 제일 작을 때 : 137 / maxWidth 제일 클 때 : 285 */}
            {/* <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
              <Box width="80%" maxWidth="285px">
                <Card sx={{ pt: 3, px: 5, backgroundColor: 'primary.main' }}>
                  <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                    제목 1
                  </Typography>
                </Card>
              </Box>
            </Box> */}

            {/* <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <Card sx={{ pt: 3, px: 5, backgroundColor: 'primary.main' }}>
                <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                  제목 1
                </Typography>
              </Card>
            </Grid> */}
          </>
        )}

      </Container>

      {/* 위젯 Stack */}
      {/* {isDesktop && (
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          justifyContent="center" // justifyContent="flex-start"
          spacing={{ xs: 0.5, sm: 1.5 }}
          sx={{
            mr: 1, mt: themeLayout !== 'vertical' ? 0 : 1.5,
            overflow: 'hidden',
          }}
        >
          <StyledRoot>
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>위젯 툴팁</span>}>
              <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', width: '100%' }}>
                <Stack direction="column" spacing={1}>
                  <Typography variant="subtitle2" noWrap sx={{ color: 'text.primary', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    위젯
                  </Typography>
                  <Typography variant="body2" noWrap sx={{ color: 'text.secondary', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    위젯을 추가하거나 수정합니다.
                  </Typography>
                </Stack>
              </Box>
            </Tooltip>
          </StyledRoot>
        </Stack>
      )} */}

      <Stack
        flexGrow={!isDesktop ? 1 : undefined} // isDesktop이 false일 때만 flexGrow를 1로 설정합니다.
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <AccountPopover />
      </Stack>

      <ConfirmDialog
        open={openFcmAlarmConfirm}
        onClose={() => setOpenFcmAlarmConfirm(false)}
        title="알림 디바이스 등록"
        content={
          <>
            현재 접속한 디바이스로 알림을 받으려면 <strong>'등록'</strong> 누르세요.<br />
            등록 시, 기존에 등록된 디바이스는 해제되며 현재 디바이스로 신규 등록됩니다.<br />
            {/* 지금 등록하지 않고 나중에 등록하려면 <strong>'취소'</strong>를 누르세요.<br /> */}
            (<strong>'프로필 아이콘 ＞ 계정 설정 ＞ 알림'</strong> 에서 알림 받을 디바이스 변경 가능)
          </>
        }
        action={
          <Button
            variant="contained"
            color="primary"
            onClick={handleFcmAlarm}
          >
            등록
          </Button>
        }
      />
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
