import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ResetPasswordPage,

  // App: Project
  ProfilePage,

  // App: Project
  ProjectPage,
  ProjectDashboardPage,

  // App: Issue
  IssueListPage,
  IssueReportTemplatePage,
  // IssueNotificationPage,
  IssueDataExtractsPage,

  // App: Insight
  InsightDataConnectorPage,
  // InsightDashBoard,
  InsightDashBoardAdd,
  InsightDashBoardEdit,

  // App: File
  FilePage,
  FileFoldersPage,
  FileHistoryPage,

  // App: Member
  MemberPage,

  // App: ToDo
  ToDoListPage,

  // Error
  ErrorPage,

  // Dashboard: General
  GeneralAppPage,
  GeneralFilePage,
  GeneralBankingPage,
  GeneralBookingPage,
  GeneralEcommercePage,
  GeneralAnalyticsPage,
  // Dashboard: User
  UserListPage,
  UserEditPage,
  UserCardsPage,
  UserCreatePage,
  UserProfilePage,
  UserAccountPage,
  // Dashboard: Ecommerce
  EcommerceShopPage,
  EcommerceCheckoutPage,
  EcommerceProductListPage,
  EcommerceProductEditPage,
  EcommerceProductCreatePage,
  EcommerceProductDetailsPage,
  // Dashboard: Invoice
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceCreatePage,
  InvoiceEditPage,
  // Dashboard: Blog
  BlogPostsPage,
  BlogPostPage,
  BlogNewPostPage,
  // Dashboard: FileManager
  FileManagerPage,
  // Dashboard: App
  ChatPage,
  MailPage,
  CalendarPage,
  KanbanPage,
  //
  BlankPage,
  PermissionDeniedPage,
  //
  CallbackPage,
  Page500,
  Page403,
  Page404,
  HomePage,
  FaqsPage,
  AboutPage,
  Contact,
  PricingPage,
  PaymentPage,
  ComingSoonPage,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  FoundationColorsPage,
  FoundationTypographyPage,
  FoundationShadowsPage,
  FoundationGridPage,
  FoundationIconsPage,
  //
  MUIAccordionPage,
  MUIAlertPage,
  MUIAutocompletePage,
  MUIAvatarPage,
  MUIBadgePage,
  MUIBreadcrumbsPage,
  MUIButtonsPage,
  MUICheckboxPage,
  MUIChipPage,
  MUIDataGridPage,
  MUIDialogPage,
  MUIListPage,
  MUIMenuPage,
  MUIPaginationPage,
  MUIPickersPage,
  MUIPopoverPage,
  MUIProgressPage,
  MUIRadioButtonsPage,
  MUIRatingPage,
  MUISliderPage,
  MUIStepperPage,
  MUISwitchPage,
  MUITablePage,
  MUITabsPage,
  MUITextFieldPage,
  MUITimelinePage,
  MUITooltipPage,
  MUITransferListPage,
  MUITreesViewPage,
  //
  DemoAnimatePage,
  DemoCarouselsPage,
  DemoChartsPage,
  DemoCopyToClipboardPage,
  DemoEditorPage,
  DemoFormValidationPage,
  DemoImagePage,
  DemoLabelPage,
  DemoLightboxPage,
  DemoMapPage,
  DemoMegaMenuPage,
  DemoMultiLanguagePage,
  DemoNavigationBarPage,
  DemoOrganizationalChartPage,
  DemoScrollbarPage,
  DemoSnackbarPage,
  DemoTextMaxLinePage,
  DemoUploadPage,
  DemoMarkdownPage,
} from './elements';
import AppLayout from '../layouts/@acs/AppLayout';
import ProjectGuard from '../guards/ProjectGuard';
import ReportTest from 'pages/app/issue/reporttest';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // BIMlize Cloud App
    {
      path: 'app',
      element: (
        <AuthGuard>
          <ProjectGuard>
            <AppLayout />
          </ProjectGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'account',
          children: [
            { element: <Navigate to="/app/account/profile" replace />, index: true },
            { path: 'profile', element: <ProfilePage /> },
          ],
        },
        {
          path: 'project',
          children: [
            { element: <Navigate to="/app/project/list" replace />, index: true },
            { path: 'list', element: <ProjectPage /> },
            { path: 'dashboard', element: <ProjectDashboardPage /> },
          ],
        },
        {
          path: 'issue',
          children: [
            { element: <Navigate to="/app/issue/list" replace />, index: true },
            { path: 'list', element: <IssueListPage /> },
            // { path: 'report-template', element: <IssueReportTemplatePage /> },
            // { path: 'notification', element: <IssueNotificationPage /> },
            // { path: 'report', element: <ReportTest /> }
            // { path: 'extracts', element: <IssueDataExtractsPage /> },
          ]
        },
        {
          path: 'insight',
          children: [
            { element: <Navigate to="/app/insight/data-connector" replace />, index: true },
            { path: 'data-connector', element: <InsightDataConnectorPage /> },
          ],
        },
        {
          path: 'file',
          children: [
            { element: <Navigate to="/app/file/folders" replace />, index: true },
            { path: 'files', element: <FilePage /> },
            { path: 'folders', element: <FileFoldersPage /> },
            { path: 'history', element: <FileHistoryPage /> },
          ],
        },
        {
          path: 'insight',
          children: [
            { element: <Navigate to="/app/insight/dashboard" replace />, index: true },
            // { path: 'dashboard', element: <InsightDashBoard /> },
            { path: 'dashboard/Add', element: <InsightDashBoardAdd /> },
            { path: 'dashboard/Edit/:id', element: <InsightDashBoardEdit /> },
          ],
        },
        {
          path: 'member',
          children: [
            { element: <Navigate to="/app/member/members" replace />, index: true },
            { path: 'members', element: <MemberPage /> },
          ],
        },
        {
          path: 'todo',
          children: [
            { element: <Navigate to="/app/todo/list" replace />, index: true },
            { path: 'list', element: <ToDoListPage /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/app/project/list" replace />, index: true },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: 'callback', element: <CallbackPage /> },
      ],
    },
    {
      path: 'error',
      element: <CompactLayout />,
      children: [
        // 예 : https://localhost:3000/error?status=403&error_code=NOT_MATCHED_USER_FOR_LINK_ACCOUNT
        { path: '?', element: <ErrorPage /> },
      ]
    },
    {
      path: '*', element: <Navigate to="/404" replace />
    },
  ]);
}
