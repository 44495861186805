import { memo } from 'react';
import { Box, Stack } from '@mui/material';
//
import { NavSectionProps, NavListProps } from '../types';
import NavList from './NavList';
//
import { useParams } from 'react-router-dom';
import { useSelector } from '../../../redux/store';
import axios from '../../../utils/axios';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

function NavSectionMini({ data, sx, ...other }: NavSectionProps) {
  const { project } = useSelector((state: any) => state.project);

  const { id } = useParams();

  const [updatedData, setUpdatedData] = useState(data);

  async function getDashboardList(projectId: string){
    try {
      const response = await axios.get(`/acs/projects/${projectId}/dashboards`);
      
      // 데이터 복사 후 수정
      let newData = [...data];

      newData.forEach(group => {
        if (group.subheader === 'insight') {
          group.items.forEach(item => {
            if (item.title === 'dashboard') {
              // 중복 항목이 추가되지 않도록 아이템의 children 배열을 초기화
              item.children = [];
              response.data.forEach((list: { name: any; id: any; }) => {
                item.children.push({
                  "title": list.name,
                  "path": `/app/insight/dashboard/Edit/${list.id}`
                });
              });
            }
          });
        }
        // 프로젝트 타입이 BIM360일 경우 'todo' 메뉴를 제거
        if (project.type === "BIM360") {
          newData = newData.filter(group => group.subheader !== 'todo');
        }
      });

      // 변경된 데이터를 상태로 설정
      setUpdatedData(newData);
    } catch (error) {
        console.log(error);
    }
  }
  
  useEffect(() => {
    try{
      getDashboardList(project.id)
    }
    catch{}
  }, [project, id]);

  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      sx={{
        px: 0.75,
        ...sx,
      }}
      {...other}
    >
      {updatedData.map((group, index) => (
        <Items key={group.subheader} items={group.items} isLastGroup={index + 1 === updatedData.length} />
      ))}
    </Stack>
  );
}

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

type ItemsProps = {
  items: NavListProps[];
  isLastGroup: boolean;
};

function Items({ items, isLastGroup }: ItemsProps) {
  return (
    <>
      {items.map((list) => (
        <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
      ))}

      {!isLastGroup && (
        <Box
          sx={{
            width: 24,
            height: '1px',
            bgcolor: 'divider',
            my: '8px !important',
          }}
        />
      )}
    </>
  );
}
