import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Tooltip, Typography } from '@mui/material';
// routes
import { PATH_APP } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';

import { useSelector } from '../../../redux/store';
import { useEffect, useState } from 'react';
import Iconify from '../../../components/iconify/Iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavProject() {
  const { project } = useSelector((state: any) => state.project);

  const [tooltip, setTooltip] = useState<string | null>(null);

  useEffect(() => {
    if (project) {
      setTooltip(`${project.name}\n${project.hubName}`);
    }
    else {
      setTooltip('프로젝트를 선택하세요.');
    }
  }, [project]);

  return (
    <Link component={RouterLink} to={PATH_APP.project.root} underline="none" color="inherit">
      <StyledRoot>
        { project && <CustomAvatar src={project.type === "BIM360" ? "/bim360.png" : "/acc.png"} variant="square" style={{width: '25px', height: '25px'}} /> }
        { !project && <Iconify icon="eva:alert-circle-outline" width={25} /> }

        <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>}>
          <Box sx={{ ml: 2, minWidth: 0 }}>
              { project && 
              <Typography variant="subtitle2" noWrap>
                {project.name}
              </Typography>}

              { !project && 
              <Typography variant="subtitle2" noWrap>
                프로젝트를 선택하세요.
              </Typography>}
          
            { project && 
              <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                {project.hubName}
              </Typography>}
          </Box>
        </Tooltip>
      </StyledRoot>
    </Link>
  );
}