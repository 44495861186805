import { Navigate, useLocation  } from 'react-router-dom';

import { useSelector } from '../redux/store';
import { PATH_APP } from '../routes/paths';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

type ProjectGuardProps = {
    children: React.ReactNode;
};

export default function ProjectGuard({ children }: ProjectGuardProps) {
    const { project } = useSelector((state: any) => state.project);
    const { pathname } = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    if (!project && pathname !== PATH_APP.project.root) {
        /// 프로필은 프로젝트 선택하지 않아도 이동 가능
        if (pathname === '/app/account' || pathname === '/app/account/profile') {
            return <> {children} </>;
        }

        // 프로필 아니면 무조건 프로젝트 페이지로 이동하게끔 -> 추후 대시보드로 이동
        // setFirstLoginSession : 프로젝트 페이지에서 firebase token 확인
        enqueueSnackbar('프로젝트를 선택하세요.', { variant: 'warning', anchorOrigin: { horizontal: 'right', vertical: 'top' }, autoHideDuration: 3000  });
        return <Navigate to={PATH_APP.project.root} />
    }

    return <> {children} </>;
}
