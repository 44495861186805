import { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation  } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/auth/LoginPage';
import { useAuthContext } from './useAuthContext';
import axios from '../utils/axios';
import config from '../configs/config';
import { useAuth0 } from '@auth0/auth0-react';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  // const auth0 = useAuth0();
  const { getAccessTokenSilently, isAuthenticated: auth0IsAuthenticated, isLoading: auth0IsLoading  } = useAuth0();

  const { isAuthenticated, isInitialized, user, loading, logout, logoutComplete } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  const [isAdskAccount, setAdskAccount] = useState<boolean>(false);


  /// 오토데스크 계정 연동 체크 (redis cache가 남아있어서 테스트를 위해서는 adsk_account와 auth0_account 두 테이블에서 해당 계정을 알맞게 삭제해야함)
  const adskAuthCheck = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    // axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    if (isAuthenticated) {
      await axios.get('/aps/w/auth/me').then((response) => {
        if (response!.status === 200) {
          setAdskAccount(true);
        }
      })
      .catch(async () => {
        const callbackUrl = `${window.location.origin}${pathname}`;
        const response = await axios.post('/aps/w/auth/url', { callbackUrl: callbackUrl });
        window.location.replace(response.data);
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    adskAuthCheck();
  }, [adskAuthCheck]);


  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated || !user) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login root={pathname} />;
  }

  if (isAuthenticated && !isAdskAccount) {
    /// state의 isInitialized와 isAuthenticated가 로컬 스토리지로 인해 true로 남아있어 무한 LoadingScreen 경우
    /// Auth0 인증이 만료된 경우 의미 없으므로 로그아웃 및 Auth0 로그인
    if (!loading && !auth0IsAuthenticated && !auth0IsLoading) {
      logout();
      logoutComplete();
      // return <Navigate to='/' />;
      if (pathname !== requestedLocation) {
        setRequestedLocation(pathname);
      }
      return <Login root={pathname} />;
    }
    return <LoadingScreen />;
    // return <></>
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
