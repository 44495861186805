import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../../utils/axios';
import { IDataConnectorRequest, IDataConnectorRequestState } from '../../../@types/@acs/dataConnector';

// ----------------------------------------------------------------------

const initialState: IDataConnectorRequestState = {
    isLoading: false,
    error: null,
    requests: [],
};

const slice = createSlice({
    name: 'data-request',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

         // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.error = initialState.error;
            state.requests = initialState.requests;
        },

        // GET REQUESTS
        getRequestsSuccess(state, action) {
            state.isLoading = false;
            state.requests = action.payload;
            state.error = null;
        },
    }
});

export default slice.reducer;

// Actions
export const {
    initialize
} = slice.actions;

export function getRequests(hubId: string, projectId: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await axios.get(`/aps/o/data-connector/v1/accounts/${hubId.slice(2)}/requests`);
            //  /aps/o/data-connector/v1/accounts/{accountId}/requests
            //console.log(response)
            // const response = await axios.get(`/aps/data/accounts/${hubId}/projects/${projectId}/requests?force=true`);

            const list: IDataConnectorRequest[] = response.data.results.map((data: any) => {
                //const params = new URLSearchParams(new URL(data.callbackUrl).search);
                //const useValue = params.get("use");
                let useValue;
                if(data.callbackUrl != null){
                    const params = new URLSearchParams(new URL(data.callbackUrl).search);
                    useValue = params.get("use");
                }
                //console.log(useValue);
                return {
                    id: data.id,
                    isActive: data.isActive,
                    accountId: data.accountId,
                    projectId: data.projectId,
                    createdByName: data.createdBy?.name,
                    createdByEmail: data.createdByEmail,
                    createdByAvatar: data.createdBy?.avatar,
                    createdAt: new Date(data.createdAt),
                    effectiveFrom: new Date(data.effectiveFrom),
                    callbackUrl: data.callbackUrl,
                    use: useValue,
                } as IDataConnectorRequest;
            });

            dispatch(slice.actions.getRequestsSuccess(list));
        } catch (error) {
            console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
};