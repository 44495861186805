import { Core } from "@grapecity/activereports";
import config from 'configs/config';

/// ActiveReportsJS 90일 테스트 배포라이선스 등록 (2023.11.13 ~ 2024.02.11) - 메일 참조
// Core.setLicenseKey("");

/// ActiveReportsJS 정식 배포 배포라이선스 등록 (2024.10.28) - 메일 참조
// Core.setLicenseKey(config.form.arJsLicenseKey);

function initializeLicense() {
    const licenseKey = config.form.arJsLicenseKey;
    if (licenseKey) {
        Core.setLicenseKey(licenseKey);
    } else {
        alert("라이선스 키가 정의되지 않았습니다.");
    }
}

initializeLicense();