import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ProjectUsers, ProjectState, Schedule } from '../../../@types/@acs/notification';
import axios from '../../../utils/axios';


// ----------------------------------------------------------------------

const initialState: ProjectState = {
    isLoading: false,
    error: null,
    projectUsers: [],
    schedule: null,
    scheduleState: null,
    projectUserState: null,
};

const slice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

         // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },

        initialize(state) {
            state.isLoading = initialState.isLoading;
            state.projectUsers = initialState.projectUsers;
            state.schedule = initialState.schedule;
            state.scheduleState = initialState.scheduleState;
        },

        // GET Project Users
        getProjectUsersSuccess(state, action) {
            state.projectUsers = action.payload;
            //state.isLoading = false;
        },

        getScheduleSuccess(state, action) {
            state.schedule = action.payload;
            state.isLoading = false;
        },

        patchScheduleSuccess(state, action) {
            state.scheduleState = action.payload;
        },

        patchProjectUserSuccess(state, action) {
            state.projectUserState = action.payload;
        },
    }
});

export default slice.reducer;

// Actions
export const {
    initialize,
    getProjectUsersSuccess,
    getScheduleSuccess,
    patchScheduleSuccess,
} = slice.actions;
      
export function getProjectUsers(id: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            const response = await axios.get(`/aps/projects/${id}/users?force=true`);
            const list: ProjectUsers[] = response.data.map((data: any) => {
                return {
                    id: data.id,
                    email: data.email,
                    name: data.name,
                    adskId: data.adskId,
                    imageUrl: data.imageUrl,
                    PhoneNumber: data.PhoneNumber,
                    notifyIssue: data.notifyIssue,
                } as ProjectUsers;
            });

            dispatch(slice.actions.getProjectUsersSuccess(list));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
};

export function getSchedule(id: string) {
    return async (dispatch: Dispatch) => {
        try {
        dispatch(slice.actions.startLoading());
        const response = await axios.get(`/acs/projects/${id}/schedule`);
        const data = response.data; // 응답 데이터
        const schedule: Schedule = {
            mon: data.mon,
            tue: data.tue,
            wed: data.wed,
            thur: data.thur,
            fri: data.fri,
            sat: data.sat,
            sun: data.sun,
            runAt: data.runAt,
        };

        dispatch(slice.actions.getScheduleSuccess(schedule));
        } catch (error) {
        console.log(error);
        dispatch(slice.actions.hasError(error));
        }
    };
}


export function patchSchedule(id: string, arr: boolean[], run_at: string) {
    return async (dispatch: Dispatch) => {
      try {
        
        const [real_time, sun, mon, tue, wed, thur, fri, sat] = arr;
  
        const response = await axios.put(`/acs/projects/${id}/schedule`, {
          real_time,
          sun,
          mon,
          tue,
          wed,
          thur,
          fri,
          sat,
          run_at
        });
  
        const status = response.status;

        dispatch(slice.actions.patchScheduleSuccess(status));
      } catch (error) {
        console.log(error);
        dispatch(slice.actions.hasError(error));
      } 
    };
  }

  export function patchProjectUser(userId: string, projectId: string, notifyIssue: boolean) {
    return async (dispatch: Dispatch) => {
      try {
          
        const response = await axios.patch(`/acs/projects/${projectId}/users/${userId}`, {
            notifyIssue
        });
        const status = response.status;

        dispatch(slice.actions.patchProjectUserSuccess(status));
        getProjectUsers(projectId);

      } catch (error) {
        console.log(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }
  
  
  
  
  
  
  

