import { Helmet } from 'react-helmet-async';
// sections
import Login from '../../sections/auth/Login';
// import Login from '../../sections/auth/LoginAuth0';
import LoadingScreen from 'components/loading-screen/LoadingScreen';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

type Props = {
  root: string;
};

export default function LoginPage({
  root,
}: Props) {
  const { loginWithRedirect } = useAuth0();

  async function fetchData() {
    try {
      await loginWithRedirect({
        appState: {
          returnTo: root,
        },
        authorizationParams: {
          prompt: "login",
        },
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <LoadingScreen />
    </>
  );
}

// export default function LoginPage() {
//   return (
//     <>
//       <Helmet>
//         <title> 로그인 | Bimlize Cloud</title>
//       </Helmet>

//       <Login />
//     </>
//   );
// }