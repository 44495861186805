import { useState } from 'react';

import { Outlet } from 'react-router-dom';
// @mui
import { Box, Button, Fab, IconButton, List, ListItem, ListItemText, Modal, TextField, Typography } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from '../dashboard/Main';
import Header from './header/Header';
import NavMini from '../dashboard/nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from '../dashboard/nav/NavHorizontal';
import Iconify from 'components/iconify';
import { StyledRoot } from 'layouts/login/styles';
import { PartLoadingScreen } from 'components/loading-screen';

// ----------------------------------------------------------------------

export default function AppLayout() {
  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const [openChat, setOpenChat] = useState(false); 

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenChat = () => {
    setOpenChat(true);
  };

  const handleCloseChat = () => {
    setOpenChat(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  const ChatInterface = () => {
    const [messages, setMessages] = useState([
      { sender: 'bot', text: '안녕하세요! 무엇을 도와드릴까요?' },
    ]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
  
    const handleSend = async () => {
      if (input.trim() === '') return;
  
      const userMessage = { sender: 'user', text: input };
      setMessages((prev) => [...prev, userMessage]);
      setInput('');
      setLoading(true);
  
      try {
        // const response = await axios.post('http://localhost:5000/api/chat', { message: input });
        // const botMessage = { sender: 'bot', text: response.data.reply };
        const botMessage = { sender: 'bot', text: '채팅 결과 값' };
        setMessages((prev) => [...prev, botMessage]);
      } catch (error) {
        console.error(error);
        const botMessage = { sender: 'bot', text: '죄송합니다. 응답을 생성하는 데 문제가 발생했습니다.' };
        setMessages((prev) => [...prev, botMessage]);
      } finally {
        // setLoading(false);
        setTimeout(async () => {
          setLoading(false);
        }, 1000);
      }
    };
  
    const handleKeyPress = (e: { key: string; }) => {
      if (e.key === 'Enter') {
        handleSend();
      }
    };
  
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {/* 메시지 목록 */}
        <List
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            mb: 2,
          }}
        >
          {messages.map((msg, index) => (
            <ListItem key={index} sx={{ justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start' }}>
              <Box
                sx={{
                  bgcolor: msg.sender === 'user' ? 'primary.main' : 'grey.300',
                  color: msg.sender === 'user' ? 'white' : 'black',
                  borderRadius: 2,
                  p: 1,
                  maxWidth: '70%',
                }}
              >
                <ListItemText primary={msg.text} />
              </Box>
            </ListItem>
          ))}
          {loading && (
            <ListItem sx={{ justifyContent: 'flex-start' }}>
              <Box
                sx={{
                  bgcolor: 'grey.300',
                  borderRadius: 2,
                  p: 1,
                  maxWidth: '70%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* {isLoading ? <StyledRoot sx={{ position: 'absolute', left: '50%', top: '10%', transform: 'translateX(-50%)', alignItems: 'center', zIndex: 2 }}><PartLoadingScreen /></StyledRoot> : null} */}
                <StyledRoot sx={{ position: 'absolute', left: '50%', top: '10%', transform: 'translateX(-50%)', alignItems: 'center', zIndex: 2 }}><PartLoadingScreen /></StyledRoot>
                <Typography variant="body2">잠시만 기다려주세요. 응답 중입니다...</Typography>
              </Box>
            </ListItem>
          )}
        </List>
  
        {/* 입력창 */}
        <Box sx={{ display: 'flex' }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="메시지를 입력하세요..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <Button variant="contained" color="primary" onClick={handleSend} sx={{ ml: 1 }}>
            전송
          </Button>
        </Box>
      </Box>
    );
  };

  const Chatbot = () => (
    <>
      {/* 플로팅 액션 버튼 */}
      <Fab
        color="primary"
        aria-label="자연어 필터링"
        onClick={handleOpenChat}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          // zIndex: (theme) => theme.zIndex.tooltip + 1, // 이슈 상세 정보 창보다 위에 위치해야 하므로 주석
        }}
      >
        <Iconify icon="fluent:settings-chat-24-regular" />
      </Fab>

      {/* 채팅 창 모달 */}
      <Modal
        open={openChat}
        onClose={handleCloseChat}
        aria-labelledby="chatbot-modal-title"
        aria-describedby="chatbot-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            // bottom: 80, // FAB 위에 위치
            bottom: 16, // FAB 위에 위치
            right: 16,
            width: { xs: '90%', sm: 400 },
            maxWidth: '100%',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 2,
            // height: 500, // 고정 높이 추가
            // display: 'flex',
            // flexDirection: 'column',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="chatbot-modal-title" variant="h6" component="h2">
              검색 필터링
            </Typography>
            <IconButton onClick={handleCloseChat}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Box>
          {/* 채팅 내용 */}
          <Box id="chatbot-modal-description" sx={{ mt: 2 }}>
            {/* 여기에 채팅 컴포넌트 또는 iframe을 통합할 수 있습니다 */}
            <Typography>검색 필터링에 오신 것을 환영합니다! 원하시는 이슈를 검색해보세요!</Typography>
            {/* 예시 채팅 인터페이스 */}
            <ChatInterface />
            {/*
              아래를 실제 채팅 구현으로 교체하세요.
              <ChatInterface />
            */}
          </Box>
        </Box>
      </Modal>
    </>
  );

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>

      <Chatbot /> 
    </>
  );
}
