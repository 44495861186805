import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import projectReducer from './slices/@acs/project';
import issueReducer from './slices/@acs/issue';
import requestReducer from './slices/@acs/dcrequest';
import notificationReducer from './slices/@acs/notification';
import fileReducer from './slices/@acs/file';
import formReducer from './slices/@acs/form';
import fcmAlarmReducer from './slices/@acs/fcmAlarm';
import jobReducer from './slices/@acs/dcjob';
import ossReducer from './slices/@acs/oss';
import forecastReducer from './slices/@acs/forecast';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const projectPersisConfig = {
  key: 'project',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['project']
}

export const issuePersisConfig = {
  key: 'issue',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['issue']
}

export const notificationPersisConfig = {
  key: 'notification',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['notification']
}

export const filePersisConfig = {
  key: 'file',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['file']
}

export const formPersisConfig = {
  key: 'form',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['form']
}

export const fcmAlarmPersisConfig = {
  key: 'fcmAlarm',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['fcmAlarm']
}

export const forecastPersisConfig = {
  key: 'forecast',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['forecast']
}

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  project: persistReducer(projectPersisConfig, projectReducer),
  issue: persistReducer(issuePersisConfig, issueReducer),
  notification: persistReducer(notificationPersisConfig, notificationReducer),
  file: persistReducer(filePersisConfig, fileReducer),
  form: persistReducer(formPersisConfig, formReducer),
  fcmAlarm: persistReducer(fcmAlarmPersisConfig, fcmAlarmReducer),
  request: requestReducer,
  job: jobReducer,
  oss: ossReducer,
  forecast: persistReducer(forecastPersisConfig, forecastReducer),
});

export default rootReducer;
