// @mui
import { List, Stack } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
//
import { useParams } from 'react-router-dom';
import { NavSectionProps } from '../types';
import { StyledSubheader } from './styles';
import NavList from './NavList';
import { useSelector } from '../../../redux/store';
import axios from '../../../utils/axios';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { project } = useSelector((state: any) => state.project);

  const { translate } = useLocales();

  const { id } = useParams();

  const [updatedData, setUpdatedData] = useState(data);

  async function getDashboardList(projectId: string){
    try {
      const response = await axios.get(`/acs/projects/${projectId}/dashboards`);

      // 데이터 복사 후 수정
      let newData = [...data];

      newData.forEach(group => {
        if (group.subheader === 'insight') {
          group.items.forEach(item => {
            if (item.title === 'dashboard') {
              // 중복 항목이 추가되지 않도록 아이템의 children 배열을 초기화
              item.children = [];
              response.data.forEach((list: { name: any; id: any; }) => {
                item.children.push({
                  "title": list.name,
                  "path": `/app/insight/dashboard/Edit/${list.id}`
                });
              });
            }
          });
        }
        // 프로젝트 타입이 BIM360일 경우 'todo' 메뉴를 제거
        if (project.type === "BIM360") {
          newData = newData.filter(group => group.subheader !== 'todo');
        }
      });

      // 변경된 데이터를 상태로 설정
      setUpdatedData(newData);
    } catch (error) {
        console.log(error);
    }
  }
  
  useEffect(() => {
    try{
      getDashboardList(project.id)
    }
    catch{}
  }, [project, id]);  // [, project, id] 기존 식. 페이지 변경이 한번씩 리스트 검색

  return (
    <Stack sx={sx} {...other}>
      {updatedData.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
