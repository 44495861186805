interface Config {
    environment: string;
    apiServerUrl: string;
    aps: ApsConfig;
    auth0: Auth0Config;
    fcm: FcmConfig
    openWeather: OpenWeatherConfig
    form: FormConfig
}

interface ApsConfig {
    baseUrl: string;
    loginUrl: string;
    authorizationCodeRequestUrl: string;
}

interface Auth0Config {
    clientId: string;
    domain: string;
    callbackUrl: string;
    audience: string;
}

interface FcmConfig {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
    vapidKey: string;
}

interface OpenWeatherConfig {
    apiKey: string;
}

interface FormConfig {
    arJsLicenseKey: string; // ActiveReportsJS license key
    basicForms: string[];
    customForms: string[];
}

const config: Config = {
    environment: process.env.REACT_APP_ENV as string,
    apiServerUrl: process.env.REACT_APP_API_SERVER_URL as string,
    aps: {
        baseUrl: process.env.REACT_APP_APS_BASE_URL as string,
        loginUrl: process.env.REACT_APP_APS_LOGIN_URL as string,
        authorizationCodeRequestUrl: process.env.REACT_APP_APS_AUTHORIZATION_CODE_REQUEST_URL as string,
    },
    auth0: {
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
        domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
        callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL as string,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
    },
    fcm: {
        apiKey: process.env.REACT_APP_FCM_API_KEY as string,
        authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN as string,
        projectId: process.env.REACT_APP_FCM_PROJECT_ID as string,
        storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET as string,
        messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID as string,
        appId: process.env.REACT_APP_FCM_APP_ID as string,
        measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID as string,
        vapidKey: process.env.REACT_APP_FCM_VAPID_KEY as string,
    },
    openWeather: {
        apiKey: process.env.REACT_APP_OPEN_WEATHER_API_KEY as string,
    },
    form: {
        arJsLicenseKey: process.env.REACT_APP_ARJS_LICENSE_KEY as string,
        basicForms: (process.env.REACT_APP_BASIC_FORMS || '').split(','),
        customForms: (process.env.REACT_APP_CUSTOM_FORMS || '').split(','),
    }
};

export default config;